import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import messageSquareFill from '@iconify/icons-eva/message-square-fill';
import videoFill from '@iconify/icons-eva/video-fill';
import gridOutline from '@iconify/icons-eva/grid-outline';
import creditCardFill from '@iconify/icons-eva/credit-card-fill';
import fileOutline from '@iconify/icons-eva/file-outline';
import attachFill from '@iconify/icons-eva/credit-card-outline';
import { DASHBOARD_ROUTES, POST_ROUTES, SPENDERS_ROUTES, USER_ROUTES } from '../../constants/SiteRoutes';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: DASHBOARD_ROUTES.STATS,
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'users',
    path: USER_ROUTES.LIST,
    icon: getIcon(peopleFill),
    children: [
      {
        title: 'All Users',
        path: USER_ROUTES.ALL,
        icon: getIcon(peopleFill)
      },
/*      {
        title: 'All Users Old',
        path: USER_ROUTES.LIST,
        icon: getIcon(peopleFill)
      }, */
      {
        title: 'Users with card',
        path: USER_ROUTES.WITH_CARD,
        icon: getIcon(peopleFill)
      },
      {
        title: 'Users with valid card',
        path: USER_ROUTES.WITH_VALID_CARD,
        icon: getIcon(peopleFill)
      },
      {
        title: 'Users without card',
        path: USER_ROUTES.WITHOUT_VALID_CARD,
        icon: getIcon(peopleFill)
      },
      {
        title: 'With Banned Words',
        path: USER_ROUTES.WITH_BANNED_WORDS,
        icon: getIcon(peopleFill)
      },
    ]
  },
  {
    title: 'Creators',
    path: USER_ROUTES.LIST,
    icon: getIcon(peopleFill),
    children: [
      {
        title: 'New Creators',
        path: USER_ROUTES.NEW_CREATORS,
        icon: getIcon(peopleFill),
      },
      {
        title: 'All Creators',
        path: USER_ROUTES.ALL_CREATORS,
        icon: getIcon(peopleFill)
      },
      {
        title: 'Favourite Creators',
        path: USER_ROUTES.FAVOURITE_CREATORS,
        icon: getIcon(peopleFill)
      },
    ]
  },
  {
    title: 'Creators Banking Reviews',
    path: DASHBOARD_ROUTES.REVIEWS,
    icon: getIcon(peopleFill),
    hasBadge: true,
    badgeKey: 'bankingRequestCount',
  },
/*  {
    title: 'New Creators',
    path: USER_ROUTES.NEW_CREATORS,
    icon: getIcon(peopleFill)
  }, */
  {
    title: 'Post',
    path: POST_ROUTES.LIST,
    icon: getIcon(fileTextFill),
  },
  {
    title: 'Reported Posts',
    path: POST_ROUTES.REPORTED,
    icon: getIcon(fileTextFill),
    hasBadge: true,
    badgeKey: 'reportedPostCount',
  },
  // {
  //   title: 'Spenders',
  //   path: SPENDERS_ROUTES.TOP,
  //   icon: getIcon(peopleFill),
  //   children:[
  //     {
  //       title: 'Top Spenders',
  //       path: SPENDERS_ROUTES.TOP,
  //       icon: getIcon(peopleFill)
  //     },
  //     {
  //       title: 'Recent Spenders',
  //       path: SPENDERS_ROUTES.RECENT,
  //       icon: getIcon(peopleFill)
  //     },
  //   ]
  // },
  {
    title: 'Messages',
    path: DASHBOARD_ROUTES.MEDIAS,
    icon: getIcon(messageSquareFill),
    children:[
      {
        title: 'Medias',
        path: DASHBOARD_ROUTES.MEDIAS,
        icon: getIcon(videoFill),
      },
      {
        title: 'Mass Messages',
        path: DASHBOARD_ROUTES.MASS_MESSAGES,
        icon: getIcon(messageSquareFill)
      },
    ]
  },
  {
    title: 'All Medias',
    path: DASHBOARD_ROUTES.ALL_MEDIAS,
    icon: getIcon(videoFill)
  },
  {
    title: 'Transactions',
    path: DASHBOARD_ROUTES.TRANSACTIONS,
    icon: getIcon(attachFill)
  },
  
  {
    title: 'Settings',
    path: DASHBOARD_ROUTES.CONTENT,
    icon: getIcon(shoppingBagFill),
    children:[
      {
        title: 'Pages',
        path: DASHBOARD_ROUTES.CONTENT,
        icon: getIcon(shoppingBagFill)
      },
      {
        title: 'Banned Words',
        path: DASHBOARD_ROUTES.BANNED_WORDS,
        icon: getIcon(shoppingBagFill)
      },
    ]
  },

/*  {
    title: 'Top Spenders',
    path: SPENDERS_ROUTES.TOP,
    icon: getIcon(peopleFill)
  },
  {
    title: 'Recent Spenders',
    path: SPENDERS_ROUTES.RECENT,
    icon: getIcon(peopleFill)
  }, */
/*  {
    title: 'Reported Posts',
    path: POST_ROUTES.REPORTED,
    icon: getIcon(fileTextFill)
  }, */
  {
    title: 'Referrals',
    path: DASHBOARD_ROUTES.REFERRALS,
    icon: getIcon(pieChart2Fill)
  },
  // {
  //   title: 'Payout Requests',
  //   path: DASHBOARD_ROUTES.PAYOUTS,
  //   icon: getIcon(creditCardFill),
  //   hasBadge: true,
  //   badgeKey: 'payoutRequestCount'
  // },
  {
    title: 'MassPay',
    path: DASHBOARD_ROUTES.MASSPAYS,
    icon: getIcon(creditCardFill),
    hasBadge: true,
    badgeKey: 'payoutRequestCount',
    children:[
      {
        title: 'All MassPay',
        path: DASHBOARD_ROUTES.MASSPAYS,
        icon: getIcon(peopleFill)
      },
      {
        title: 'MassPay Export',
        path: DASHBOARD_ROUTES.MASSPAY_EXPORT,
        icon: getIcon(peopleFill)
      },
    ]
  },
  // {
  //   title: 'Twins Subscribers',
  //   path: SPENDERS_ROUTES.SUBSCRIBERS,
  //   icon: getIcon(peopleFill)
  // },
  {
    title: 'Platform fees',
    path: DASHBOARD_ROUTES.PLATFORM_FEES,
    icon: getIcon(gridOutline),
    children:[
      {
        title: 'Histories',
        path: DASHBOARD_ROUTES.PLATFORM_FEES,
        icon: getIcon(gridOutline)
      },
      {
        title: 'Trigger Platform fee',
        path: DASHBOARD_ROUTES.PLATFORM_FEES_INFOS,
        icon: getIcon(gridOutline)
      },
      {
        title: 'Platform Users',
        path: DASHBOARD_ROUTES.PLATFORM_USERS,
        icon: getIcon(gridOutline)
      },
    ]
  },


];

export default sidebarConfig;
